import { HttpMethod } from "./Types";
const httpcall = (
    url: string,
    method: HttpMethod,
    body: any,
    token: any
): Promise<any> => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};

// Token Not required

// ---------------HomePage LINKS-------------------

const getCategories = (method: HttpMethod, body: any, token: any) => {
    return httpcall("web_list_all_categories", method, body, token);
};

const getTutors = (method: HttpMethod, body: any, token: any) => {
    return httpcall("tutors/web_list_all_tutors", method, body, token);
};

const getCoursesByCategory = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`web_courses_list`, method, body, token);
};
const getAllCourses = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`web_list_courses`, method, body, token);
};
const getCoursesDetailsByCourseId = (
    method: HttpMethod,
    body: any,
    token: any,
    id: any
) => {
    return httpcall(`web_course_detail/${id}`, method, body, token);
};
const request_demo = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`parent/web_request_demo`, method, body, token);
};

const student_registration_form = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall("parent/enroll_course_web", method, body, token);
};

const student_Search_By_Parent_Mobile = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(
        `parent/get_parentdetails_with_students`,
        method,
        body,
        token
    );
};

//--------------TUTOR LINKS---------------

//--------------------lOGIN-----------------------------------
const Tutor_Login = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/login`, method, body, token);
};
const Tutor_SignUp_Send_OTP = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/signup/sendotp`, method, body, token);
};
const Tutor_SignUp_Verify_OTP = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/signup/verifyotp`, method, body, token);
};
const Tutor_SignUp_Details = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/signup`, method, body, token);
};
const Tutor_Login_Verify_OTP = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/login/verifyTutor`, method, body, token);
};
const Logout = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/add_activitylog`, method, body, token);
};

//-------------------EARNINGS-----------------
const Tutor_Earnings = (
    method: HttpMethod,
    body: any,
    token: any,
    offset: any
) => {
    return httpcall(
        `tutors/get_tutor_earnings?toSkip=${offset}`,
        method,
        body,
        token
    );
};
const Tutor_Payouts = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/get_payouts`, method, body, token);
};
const Get_Payout_Details = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/get_payout_detail`, method, body, token);
};

//----------------------SCHEDULE-CLASSES-----------------------------
const Schedule_Classes = (
    method: HttpMethod,
    body: any,
    token: any,
    id: string | null
) => {
    return httpcall(
        `tutors/get_scheduled_classes?Status=${id}`,
        method,
        body,
        token
    );
};
const get_Lesson_Plan = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/get_pending_lessonplans`, method, body, token);
};

const Schedule_Classes_Details = (
    method: HttpMethod,
    body: any,
    token: any,
    id: string | null
) => {
    return httpcall(
        `tutors/get_schedule_class_details?ScheduleClassID=${id}`,
        method,
        body,
        token
    );
};

const Complete_Class = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/complete_schedule_class`, method, body, token);
};
const Cancel_Class = (
    method: HttpMethod,
    body: any,
    token: any,
    ScheduleId: any
) => {
    return httpcall(
        `tutors/cancel_schedule_class/${ScheduleId}`,
        method,
        body,
        token
    );
};
const Reschedule = (
    method: HttpMethod,
    body: any,
    token: any,
    ScheduleId: any
) => {
    return httpcall(
        `tutors/reschedule_schedule_class/${ScheduleId}`,
        method,
        body,
        token
    );
};
const cancelClassValidate = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/canceltime_validate`, method, body, token);
};
const MarkAttendace = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/mark_studentattendance`, method, body, token);
};
const MarkAttendaceDemo = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/markstudentattendance_demo`, method, body, token);
};
const ReportSubmitStudent = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/submit_report`, method, body, token);
};
const get_all_scheduledClasses = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(
        `tutors/get_Scheduled_Classes_for_calender`,
        method,
        body,
        token
    );
};

//--------------Calender--------------------
const searchActiveTutor = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/admin_search_active_tutors`, method, body, token);
};
const searchActiveStudent = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/search_active_students`, method, body, token);
};

// -------------------------Batch Requests ------------------

const getBatchRequests = (
    method: HttpMethod,
    body: any,
    token: any,
    type: number,
    Status: number
) => {
    return httpcall(
        `tutors/get_batch_requests?IsTutorAccepted=${type}&Status=${Status}`,
        method,
        body,
        token
    );
};
const getBatchRequestsDetailsByBatchId = (
    method: HttpMethod,
    body: any,
    token: any,
    id: any
) => {
    return httpcall(
        `tutors/get_batch_details?BatchID=${id}`,
        method,
        body,
        token
    );
};
const ApproveRejectBatchRequests = (
    method: HttpMethod,
    body: any,
    token: any,
    id: number
) => {
    return httpcall(`tutors/accept_batch_request/${id}`, method, body, token);
};
const classesByBatchId = (
    method: HttpMethod,
    body: any,
    token: any,
    id: any
) => {
    return httpcall(
        `tutors/get_Scheduled_Classes_by_batch?BatchID=${id}`,
        method,
        body,
        token
    );
};
//----------------------Courses-----------------------
const Courses = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/get_tutor_courses`, method, body, token);
};
const LessonPlanByCourseId = (
    method: HttpMethod,
    body: any,
    token: any,
    courseId: any
) => {
    return httpcall(
        `tutors/get_lessonplan_by_course/${courseId}`,
        method,
        body,
        token
    );
};
//-----------------Apply Leave-----------------
const ApplyLeave = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/request_leave`, method, body, token);
};
const LeaveHistory = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/leave_history`, method, body, token);
};
const getLeaveBalances = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/leave_balances`, method, body, token);
};
const PendingLeaveRequest = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/pending_leaverequests`, method, body, token);
};
const updateLeave = (
    method: HttpMethod,
    body: any,
    token: any,
    LeaveRequestID: number
) => {
    return httpcall(
        `tutors/update_leaverequest/${LeaveRequestID}`,
        method,
        body,
        token
    );
};

// --------------Get Tutor Profile----------------------
const getTutorProfile = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/tutor_detail`, method, body, token);
};
const updateTutorProfile = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/update_profile`, method, body, token);
};

// ----------------TUTOR AVAILABILITY---------------
const getTutorAvailability = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/List_tutoravailability`, method, body, token);
};
const SaveTutorAvailability = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/save_tutoravailability`, method, body, token);
};
const DeleteTutorAvailability = (
    method: HttpMethod,
    body: any,
    token: any,
    Id: any
) => {
    return httpcall(
        `tutors/delete_tutoravailability/${Id}`,
        method,
        body,
        token
    );
};

//-----------Bulk Canel--------------------
const getScheduled_class_by_date = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(
        `tutors/get_scheduled_classes_by_date`,
        method,
        body,
        token
    );
};
const bulk_cancel = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/cancel_bulk_classes`, method, body, token);
};
const Notifications = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/get_tutor_notifications`, method, body, token);
};
const get_Notification_count = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/get_unseen_noticationcount`, method, body, token);
};
const Reset_Notification_count = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`tutors/update_notification_lastseen`, method, body, token);
};
const get_dashboard_class = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/upcomming_classes`, method, body, token);
};
const get_earning_class = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/earnings_summary`, method, body, token);
};
const ChallengePenalty = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/challenge_a_penality`, method, body, token);
};
const bulkCancelRequests = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/get_bulkcancel_requests`, method, body, token);
};
const getSessions = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/CreateZoom_through_lens`, method, body, token);
};
const getSessionsDemo = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/democlass_startsession`, method, body, token);
};
// ------------------------Demo Classes ------------------------

const getDemoClasses = (
    method: HttpMethod,
    body: any,
    token: any,
    status: any,
    skip: any
) => {
    return httpcall(
        `tutors/get_demo_classes?DemoClassStatus=${status}&&toSkip=${skip}`,
        method,
        body,
        token
    );
};
const getDemoClassesDetails = (
    method: HttpMethod,
    body: any,
    token: any,
    demo_Id: any
) => {
    return httpcall(
        `tutors/democlass_details?DemoClassID=${demo_Id}`,
        method,
        body,
        token
    );
};
const CompleteClassDemo = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/complete_democlass`, method, body, token);
};
const CancelClassDemo = (
    method: HttpMethod,
    body: any,
    token: any,
    id: any
) => {
    return httpcall(`tutors/cancel_demo_class/${id}`, method, body, token);
};
const updateCompleteComments = (
    method: HttpMethod,
    body: any,
    token: any,
    id: any
) => {
    return httpcall(`tutors/update_democlass/${id}`, method, body, token);
};
const updateDateTime = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/update_actualdate`, method, body, token);
};
const tutor_cancel_reports = (method: HttpMethod, body: any, token: any) => {
    return httpcall(`tutors/tutor_cancel_reports`, method, body, token);
};
const cancelledclasses_by_type = (
    method: HttpMethod,
    body: any,
    token: any
) => {
    return httpcall(`tutors/cancelledclasses_by_type`, method, body, token);
};
export const Services = {
    cancelledclasses_by_type,
    getSessionsDemo,
    tutor_cancel_reports,
    updateDateTime,
    getCategories,
    updateCompleteComments,
    CancelClassDemo,
    getDemoClassesDetails,
    getDemoClasses,
    get_dashboard_class,
    bulkCancelRequests,
    get_earning_class,
    getCoursesByCategory,
    CompleteClassDemo,
    DeleteTutorAvailability,
    Reset_Notification_count,
    Notifications,
    getScheduled_class_by_date,
    getTutors,
    get_Notification_count,
    getCoursesDetailsByCourseId,
    getSessions,
    request_demo,
    Tutor_Login,
    Tutor_Login_Verify_OTP,
    getTutorAvailability,
    getTutorProfile,
    Schedule_Classes,
    MarkAttendaceDemo,
    Get_Payout_Details,
    Schedule_Classes_Details,
    Complete_Class,
    Tutor_Payouts,
    ChallengePenalty,
    Tutor_Earnings,
    Cancel_Class,
    Reschedule,
    cancelClassValidate,
    MarkAttendace,
    ReportSubmitStudent,
    student_registration_form,
    get_all_scheduledClasses,
    searchActiveTutor,
    searchActiveStudent,
    student_Search_By_Parent_Mobile,
    getBatchRequests,
    ApproveRejectBatchRequests,
    getBatchRequestsDetailsByBatchId,
    Courses,
    LessonPlanByCourseId,
    classesByBatchId,
    ApplyLeave,
    LeaveHistory,
    getLeaveBalances,
    PendingLeaveRequest,
    updateLeave,
    get_Lesson_Plan,
    Logout,
    getAllCourses,
    updateTutorProfile,
    SaveTutorAvailability,
    bulk_cancel,
    Tutor_SignUp_Send_OTP,
    Tutor_SignUp_Verify_OTP,
    Tutor_SignUp_Details,
};
