import React from "react";
import Modal from "react-modal";
import Styles from "./Modal.module.css";
export interface props {
    modal: any;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Info: React.FC<props> = ({ modal, setModal }) => {
    const closeModal = () => {
        setModal(false);
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["Wrapper"]}>
                <ul>
                    <li>
                        <b> Monthly Holiday:</b> You are entitled to one day of
                        holiday for every completed month of rendering your
                        services. You are required to give a minimum of one day
                        notice before you can avail of this leave. You cannot
                        carry forward monthly 1 day holiday cancellation of
                        classes to the subsequent months
                    </li>
                    <li>
                        <b> Annual Holiday:</b> After 6 months (six) of
                        completed engagement from the date of joining, you are
                        entitled to 5 consecutive days of holidays in a year .
                        You are required to give a minimum of two weeks’ notice
                        to avail this holiday.
                    </li>
                    <li>
                        <b>6 Class Cancellations:</b> Due to medical emergencies
                        or other personal emergencies, you are allowed to cancel
                        6 classes per month. A minimum of 90 minutes prior
                        intimation is required to avail this absence. A proper
                        and justified reason for cancellation of classes due to
                        any emergency is required. Please do not cancel classes
                        for frivolous and unjustified reasons. You cannot carry
                        forward emergency cancellation of classes to the
                        subsequent months.
                    </li>
                    <li>
                        You are entitled to Maternity Leave of up to 90 days.
                        The instructor should have worked for at least 6 months
                        from the date of joining to avail this leave.
                    </li>
                    <li>
                        You are entitled to leave for 4 days in case of
                        Bereavement of immediate family members. (Parents,
                        In-laws. Grandparents and family constitute immediate
                        family members). Relatives are not considered as
                        immediate family members.
                    </li>
                    <li>
                        In event of hospitalization or complete bed rest due
                        medical requirements you are entitled up to 7 days of
                        holidays. You will be required to provide sufficient
                        medical records to substantiate this absence from work.
                    </li>
                    <li>
                        You cannot suffix or prefix Monthly holidays and Annual
                        Holidays to Public Holidays.
                    </li>
                </ul>
            </div>
        </Modal>
    );
};

export default Info;
